<template>
  <div class="login" :style="{ backgroundImage: 'url(' + loginBg + ')' }">
    <img class="avatar" :src="iconImg" />
    <div class="login-main">
      <el-tabs stretch>
        <el-tab-pane label="Password login">
          <el-form label-position="auto" ref="form" :model="form" size="small" :inline-message="true">
            <el-form-item prop="username" :label="$t('ifm.username')" :rules="[
              {
                required: true,
                message: $t('tips.sr'),
                trigger: ['change', 'blur'],
              },
            ]">
              <el-input class="w-full" clearable size="small" v-model="form.username" @keyup.enter.native="doLogin" />
            </el-form-item>
            <el-form-item :label="$t('ifm.psw')" prop="password" :rules="[
              {
                required: true,
                message: $t('tips.sr'),
                trigger: ['change', 'blur'],
              },
            ]">
              <el-input class="w-full" clearable type="password" size="small" show-password v-model="form.password"
                @keyup.enter.native="doLogin" />
            </el-form-item>
            <el-form-item prop="checkTa" :rules="[
              {
                required: true,
                message: 'You are required to agree to Terms of Use and Service Agreement before you can login',
                trigger: ['change', 'blur'],
              },
            ]">
              <div class="flex flex-row justify-center items-center gap-2">
                <el-checkbox v-model="form.checkTa"></el-checkbox>
                <p style="font-size: 12px; line-height: 13px;">Please make sure you have read and agree to the <a href="Terms of Use.pdf" target="_blank" style="text-decoration: underline;">Terms of Use</a> and <a href="SaaS Agreement.pdf" target="_blank" style="text-decoration: underline;">Service Agreement</a></p>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button size="medium" type="primary" :loading="btnLoading" @click="doLogin">{{ $t("btn.signIn")
              }}</el-button>
            </el-form-item>
          </el-form>
          <el-button size="medium" type="primary" plain @click="$router.back()">
            Back
          </el-button>
          <div class="flex justify-center mt-5">
            <el-link size="medium" type="primary" plain @click="openResetPage">
              Forgot Password?
            </el-link>
          </div>
        </el-tab-pane>
        <el-tab-pane label="MVP app">
          <iframe :src="url" style="width: 220px;height:264px;border: medium none; margin-bottom: 15px;"></iframe>
          <el-button size="medium" type="primary" plain @click="$router.back()">
            Back
          </el-button>
        </el-tab-pane>
      </el-tabs>
      <div>
      </div>
    </div>
  </div>
</template>
<script>
import loginIcon from "@/assets/loginIcon.png";
import loginBg from "@/assets/loginbg.png";
import { forgetPwd, mapCheckCode } from "@/services/user";
import { setToken, setMvpToken, setCheckTs, getCheckTs } from "../utils/auth";
import iconImg from "@/assets/icon.png";

const redirect = encodeURIComponent(process.env.VUE_APP_FE_BASE_API + '/loginByCode')
const url = `${process.env.VUE_APP_AUTH_BASE_API}/token/qrConnect?client_id=jput&redirect_uri=${redirect}&state=STATE`

export default {
  name: "mvpLogin",
  data() {
    return {
      form: {
        username: "",
        password: "",
        checkTa: null
      },
      value1: "",
      btnLoading: false,
      loginIcon,
      loginBg,
      url,
      iconImg,
    };
  },
  watch: {
    'form.username': function (newVal) {
      const checkTs = getCheckTs(newVal);
      if (checkTs == 'true') {
        this.form.checkTa = true;
      } else {
        this.form.checkTa = null;
      }
    },
    'form.checkTa': function (newVal) {
      if (newVal === false) {
        this.form.checkTa = null;
      }
    }
  },
  methods: {
    openResetPage() {
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          );
      };

      if (this.form.username && validateEmail(this.form.username)) {
        window.open(process.env.VUE_APP_AUTH_HOST + '/login?forgotPassword=1&email=' + this.form.username);
      } else {
        window.open(process.env.VUE_APP_AUTH_HOST + '/login?forgotPassword=1');
      }
    },
    doLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const user = {
            username: this.form.username,
            password: this.form.password,
            isMvp: true,
          };
          this.btnLoading = true;
          this.$store
            .dispatch("goLogin", user)
            .then((res) => {
              delete user["isMvp"];
              if (res.token && !res.twoFactorEnabled) {
                this.$message.success(this.$t("status.success"));
                setToken(res.token);
                setMvpToken(res.mvpToken);
                this.$store.dispatch("getUserInfo", true).then((url) => {
                  this.$router.push(url);
                });
                setCheckTs(this.form.username);
              } else {
                this.$prompt(this.$t("ifm.pevc"), {
                  confirmButtonText: this.$t("btn.comfirm"),
                  cancelButtonText: this.$t("btn.cancel"),
                  inputPattern: /\S/,
                  inputErrorMessage: this.$t("tips.sr"),
                  beforeClose: (action, instance, done) => {
                    if (action == "confirm") {
                      mapCheckCode({
                        code: instance.inputValue,
                        userName: res.username,
                      }).then((resA) => {
                        if (resA.code == 200) {
                          this.$message.success(this.$t("status.success"));
                          done();
                          setToken(resA.token);
                          this.$store.dispatch("getUserInfo", true).then((url) => {
                            this.$router.push(url);
                          });
                          setCheckTs(this.form.username);
                        } else {
                          this.$message.error(this.$t("ifm.error"));
                        }
                      });
                    } else {
                      done();
                    }
                  },
                });
              }
            })
            .finally(() => (this.btnLoading = false));
        } else {
          return false;
        }
      });
    },
    forget() {
      this.$prompt("Please input your email", {
        confirmButtonText: this.$t("btn.comfirm"),
        cancelButtonText: this.$t("btn.cancel"),
        inputPattern: /@/,
        inputErrorMessage: "Email format error",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            forgetPwd(instance.inputValue).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$t("status.success"));
                done();
                this.router.push("/");
              } else {
                this.$message.ElMessage.error(this.$t("ifm.error"));
              }
            });
          } else {
            done();
          }
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;

  .logo {
    width: 50%;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;

    .img {
      width: 300px;
      font-size: 40px;
      color: #fff;
      text-align: center;
      line-height: 70px;
      margin-top: 200px;
      margin-left: 130px;
    }

    .title {
      font-weight: bolder;
      font-size: 50px;
      text-align: left;
    }
  }
}

::v-deep .el-form-item {
  // display: unset;
}

::v-deep .el-form-item__label {
  color: $grayColor;
  font-size: 14px;
}

.login-main {
  max-width: 270px;
  
  .el-button {
    width: 100%;
    margin: 0;
  }

  .el-button {
    // border-radius: 20px;
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.el-icon-back {
  color: $grayColor;
}

.avatar {
  width: 180px;
  height: 180px;
}
</style>
